<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="saveColor"
                v-model="save")
                    | {{ saveText }}
                    v-btn(flat, dark, @click.native="save = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h2 {{$t('Configuración')}}
                    v-divider
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}...
                    template(v-else)
                        v-form
                            h3.mt-3 {{$t('Generales')}}
                            v-layout(row wrap)
                                v-flex(xs12)
                                    h4.my-2 {{$t('Términos y condiciones')}}
                                    editor(:init="$root.editor" v-model="config.terms")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Politicas de privacidad')}}
                                    editor(:init="$root.editor" v-model="config.priv")
                            h3.mt-3 {{$t('Seminario México')}}
                            v-layout(row wrap)
                                v-flex(xs12)
                                    h4.my-2 {{$t('Politicas de privacidad')}}
                                    editor(:init="$root.editor" v-model="config.policyseminario")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Tratamiento de datos')}}
                                    editor(:init="$root.editor" v-model="config.treatmentseminario")
                            h3.mt-3 {{$t('Resumen de usuario')}}
                            p {{$t('Establece la recurrencia de envío de correo electrónico informando la posición del usuario en las redes')}}
                            v-layout(row wrap)
                                v-flex(xs6)
                                    v-text-field(:label="$t('Cada')" v-model="config.resume.times")
                                v-flex(xs6)
                                    v-select(:label="$t('Periodo')" :items="period" v-model="config.resume.period")
                            h3.mt-3 {{$t('Edición de contenido')}}
                            v-layout(row wrap)
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de registro/inicio de sesión')}}
                                    editor(:init="$root.editor" v-model="config.logintext")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de inicio')}}
                                    editor(:init="$root.editor" v-model="config.hometext")
                                v-flex(xs12)
                                    h4.my-2 {{$t('Texto de red no encontrada')}}
                                    editor(:init="$root.editor" v-model="config.notfoundtext")
                            language
                                template(slot="default" slot-scope="props")
                                    v-layout(row wrap)
                                        v-flex(xs12)
                                            h4.my-2 {{$t('Términos y condiciones')}}
                                            editor(:init="$root.editor" v-model="config.translation[props.lang].terms")
                                        v-flex(xs12)
                                            h4.my-2 {{$t('Politicas de privacidad')}}
                                            editor(:init="$root.editor" v-model="config.translation[props.lang].priv")
                                        v-flex(xs12)
                                            h4.my-2 {{$t('Texto de registro/inicio de sesión')}}
                                            editor(:init="$root.editor" v-model="config.translation[props.lang].logintext")
                                        v-flex(xs12)
                                            h4.my-2 {{$t('Texto de inicio')}}
                                            editor(:init="$root.editor" v-model="config.translation[props.lang].hometext")
                                        v-flex(xs12)
                                            h4.my-2 {{$t('Texto de red no encontrada')}}
                                            editor(:init="$root.editor" v-model="config.translation[props.lang].notfoundtext")
                            color-setup(v-model="config.colors")
                            color-filter-all(v-model="config")
                            .btns.btns-right
                                v-btn(@click.native="saveConfig" :loading="loadingBtn" color="primary" large) {{$t('Guardar')}}
</template>

<script>

    import auth from 'mixins/auth'
    import merge from 'lodash/merge'
    import ColorSetup from 'components/colors/Setup'
    import ColorFilterAll from 'components/colors/SetupFilterAll'

    export default {
        components: {
            'color-setup': ColorSetup,
            'color-filter-all': ColorFilterAll
        },
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Configuración')
            }
        },
        data(){
            return {
                loadingBtn: false,
                period: [
                    {
                        text: 'Día',
                        value: 'd' 
                    },
                    {
                        text: 'Semana',
                        value: 'w' 
                    },
                    {
                        text: 'Mes',
                        value: 'm' 
                    }
                ],
                save     : false,
                preload  : false,
                saveColor: 'error',
                saveText : '',
                config: {
                    terms     : ' ',
                    priv      : ' ',
                    policyseminario: '',
                    treatmentseminario: '',
                    resume    : {
                        times  : '',
                        period : ''
                    },
                    hometext  : ' ',
                    logintext : ' ',
                    notfoundtext: ' ',
                    translation: {
                        pt: {
                            priv     : ' ',
                            terms    : ' ',
                            hometext : ' ',
                            logintext: ' ',
                            notfoundtext: ' '
                        },
                        en: {
                            priv     : ' ',
                            terms    : ' ',
                            hometext : ' ',
                            logintext: ' ',
                            notfoundtext: ' '
                        },
                        it: {
                            priv     : ' ',
                            terms    : ' ',
                            hometext : ' ',
                            logintext: ' ',
                            notfoundtext: ' '
                        },
                        fr: {
                            priv     : ' ',
                            terms    : ' ',
                            hometext : ' ',
                            logintext: ' ',
                            notfoundtext: ' '
                        },
                        de: {
                            priv     : ' ',
                            terms    : ' ',
                            hometext : ' ',
                            logintext: ' ',
                            notfoundtext: ' '
                        }
                    },
                    colors: [],

                    //- Filtros de colores
                    colors_states : [],
                    colors_cities : [],
                    colors_zones  : [],
                    colors_gender : [],
                    colors_age    : [],
                    colors_level  : [],
                }
            }
        },
        computed: {

            profile(){
                return this.$root.profile
            },
        },
        created(){
            this.fetchConfig()
        },
        methods: {
            
            fetchConfig(){

                this.preload = true

                this.$api(this, (xhr) => {
                    xhr.get('/settings').then((r) => {
                        let data = r.data
                        if(data.response){
                            
                            if(data.data.resume){
                                data.data.resume = JSON.parse(data.data.resume)
                            }

                            if(data.data.colors){
                                data.data.colors = JSON.parse(data.data.colors)
                            }

                            if(data.data.colors_states){
                                data.data.colors_states = JSON.parse(data.data.colors_states)
                            }

                            if(data.data.colors_cities){
                                data.data.colors_cities = JSON.parse(data.data.colors_cities)
                            }

                            if(data.data.colors_zones){
                                data.data.colors_zones = JSON.parse(data.data.colors_zones)
                            }

                            if(data.data.colors_gender){
                                data.data.colors_gender = JSON.parse(data.data.colors_gender)
                            }

                            if(data.data.colors_age){
                                data.data.colors_age = JSON.parse(data.data.colors_age)
                            }

                             if(data.data.colors_level){
                                data.data.colors_level = JSON.parse(data.data.colors_level)
                            }
                            
                            this.config = merge(this.config, data.data)
                            this.preload = false
                        }
                    }).catch(() => {})
                })
            },

            saveConfig(){

                let post         = this._.cloneDeep(this.config)
                post.resume      = JSON.stringify(post.resume)
                post.translation = JSON.stringify(post.translation)
                post.colors      = JSON.stringify(post.colors)

                post.colors_states = JSON.stringify(post.colors_states.map((s) => {

                    s.model.country = s.model.country.uuid
                    s.model.state   = s.model.state.uuid
                    return s
                }))

                post.colors_cities = JSON.stringify(post.colors_cities.map((s) => {

                    s.model.country = s.model.country.uuid
                    s.model.state   = s.model.state.uuid
                    s.model.city    = s.model.city.uuid

                    return s
                }))

                post.colors_zones = JSON.stringify(post.colors_zones.map((s) => {

                    s.model.country = s.model.country.uuid
                    s.model.state   = s.model.state.uuid
                    s.model.city    = s.model.city.uuid
                    s.model.zones   = s.model.zones.map((z) => z.uuid).filter(z => z != undefined)
                    return s
                }))

                post.colors_age    = JSON.stringify(post.colors_age)
                post.colors_gender = JSON.stringify(post.colors_gender)
                post.colors_level  = JSON.stringify(post.colors_level)

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/settings', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.save = true

                        if(data.response){
                            this.saveColor = 'success'
                            this.saveText = data.message
                        }else{
                            this.saveColor = 'error'
                            this.saveText = data.message
                        }
                    }).catch(() => {})
                })
            }
        }
    }

</script>